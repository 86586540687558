/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */

import { useEffect, useState } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};
const adminService = {
  _url: 'https://ultimatechoicebackend-672eh7m22a-tl.a.run.app',
  // _url: 'http://localhost:4003',

  /**
   * Hooks
   */
  GetUsers(searchQuery, refetch) {
    const [users, setUsers] = useState({
      users: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getUsers(searchQuery))
        .then((res) => {
          setUsers(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.searchText,
      refetch,
    ]);
    return {
      users_loading: status === STATUS.LOADING,
      users_error: status === STATUS.ERROR ? status : '',
      users_data: users,
    };
  },
  GetInstructor(searchQuery, refetch) {
    const [instructors, setInstructors] = useState({
      instructors: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getInstructors(searchQuery))
        .then((res) => {
          setInstructors(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.searchText,
      refetch,
    ]);
    return {
      instructors_loading: status === STATUS.LOADING,
      instructors_error: status === STATUS.ERROR ? status : '',
      instructors_data: instructors,
    };
  },
  GetAttendence(searchQuery, refetch) {
    const [attendence, setAttendence] = useState({
      attendence: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAttendence(searchQuery))
        .then((res) => {
          setAttendence(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.searchText,
      refetch,
    ]);
    return {
      attendence_loading: status === STATUS.LOADING,
      attendence_error: status === STATUS.ERROR ? status : '',
      attendence_data: attendence,
    };
  },
  async health() {
    const res = await Fetch.get(`${this._url}/admins/health`);
    if (res.status >= 200 && res.status < 300) {
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getUsers({ page = 1, pageSize = 10, searchText }) {
    let res = await Fetch.get(
      `${this._url}/api/get-users?itemsPerPage=${pageSize}&page=${page}&searchText=${searchText}`
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        users: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getInstructors({ page = 1, pageSize = 10, searchText }) {
    let res = await Fetch.get(
      `${this._url}/api/get-instructors?itemsPerPage=${pageSize}&page=${page}&searchText=${searchText}`
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        instructors: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async createUser(payload) {
    let res = await Fetch.post(`${this._url}/api/create-user`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async editUser(id, values) {
    let res = await Fetch.patch(`${this._url}/api/edit-user/${id}`, values);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteUser(id) {
    let res = await Fetch.delete(`${this._url}/api/delete-user/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getAttendence({ page = 1, pageSize = 10, searchText }) {
    let res = await Fetch.get(
      `${this._url}/api/get-attendence?itemsPerPage=${pageSize}&page=${page}&searchText=${searchText}`
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        attendence: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async createAttendenceSheet(payload) {
    let res = await Fetch.post(`${this._url}/api/create-attendence`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteAttendence(id) {
    let res = await Fetch.delete(`${this._url}/api/delete-attendece/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getStudentList() {
    let res = await Fetch.get(`${this._url}/api/get-student-list`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async downloadPdf({ id, type }) {
    let res = await Fetch.get(
      `${this._url}/api/download-pdf-doc?id=${id}&type=${type}`,
      {
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
        method: 'GET',
      }
    );
    if (res.status >= 200 && res.status < 300) {
      return (res = await res?.arrayBuffer());
    }
    throw new Error('Something went wrong');
  },

  async login({ email = '', password = '', token = '' }) {
    let res = await Fetch.post(`${this._url}/api/sign-in`, {
      email,
      password,
      token,
    });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getCurrentAdmin(adminId) {
    let res = await Fetch.get(`${this._url}/api/perms?id=${adminId}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createInstructor(payload) {
    let res = await Fetch.post(`${this._url}/api/create-instructor`, { payload });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async editInstructor(id, payload) {
    let res = await Fetch.patch(`${this._url}/api/edit-instructor/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async deleteInstructor(id) {
    let res = await Fetch.delete(`${this._url}/api/delete-instructor/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getInstructorsList() {
    let res = await Fetch.get(
      `${this._url}/api/get-instructors-list`
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getSingleInstructor(id) {
    let res = await Fetch.get(
      `${this._url}/api/get-single-instructor/${id}`
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};
export default adminService;

import styled from 'styled-components/macro';

export const LogoHolder = styled.div`
  font-weight: 700;
  border: 1px solid #000;
  padding: 8px 5px;
  border-radius: 8px;
  width: 220px;
  margin: 0 auto;
  @media (min-width: 768px) {
    width: 220px;
    margin: 0;
  }
`;
export const Img = styled.img``;

import React from 'react';

// import logo from 'assets/images/logo-1.png';
import { LogoHolder, Img } from './Logo.styles';

function LogoComp() {
  return (
    <LogoHolder>
      {/* <Img src={logo} alt="plastk" /> */}
      My Ultimate Choice Driving School
    </LogoHolder>
  );
}

export default LogoComp;
